import { request } from '@/api/_base'
import { FILTER } from '@/constants/common'

export const getSearchResult = async ({ keyword, page, size, type, time, searchtype }) => {
  if (keyword == null || keyword == undefined) {
    return null
  }
  const params = {
    keyword,
    page: page || 1,
  }

  if (FILTER.type[type]) {
    params.category = FILTER.type[type].value
  }
  if (FILTER.time[time]) {
    params.diffDay = FILTER.time[time].value
  }
  if (FILTER.searchtype[searchtype]) {
    params.searchType = FILTER.searchtype[searchtype].value
  }
  if (FILTER.size[size]) {
    if (FILTER.size[size].minSize) {
      params.minSize = FILTER.size[size].minSize 
    } else {
      params.minSize = 1
    }
    if (FILTER.size[size].maxSize) {
      params.maxSize = FILTER.size[size].maxSize 
    } else {
      params.maxSize = 1099511627776
    }
  }

  const data = await request({
    url: `/v1/tracker/search`,
    params,
    headers: {
      Authorization: localStorage.getItem('token')
    }
  })
  return data
}

export const getDetail = async ({ id }) => {
  return await request({
    url: `/v1/tracker/detail`,
    params: {
      id: id,
    },
    headers: {
      Authorization: localStorage.getItem('token')
    }
  })
}
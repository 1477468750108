<template>
  <div class="filter-wrap" @click.stop="">
    <label for="filter">筛选:</label>
    <div class="filter-item" @click.stop="chooseFilterItem(4)" :class="{'active': index == 4 && filter.show}">
      <span>{{ FILTER.searchtype[filter.searchtype]&&FILTER.searchtype[filter.searchtype].name || '模式' }}</span>
      <img :src="arrow" class="arrow" alt="大力盘"> 
      <ul v-show="index == 4 && filter.show">
        <li @click.stop="chooseFilterSearchType(-1)">系统默认</li>
        <li v-for="item in filter_search_type_keys" :key="FILTER.searchtype[item].name" @click.stop="chooseFilterSearchType(item)">{{FILTER.searchtype[item].name}}</li>
      </ul>
    </div>

    <div class="filter-item" @click.stop="chooseFilterItem(1)" :class="{'active': index == 1 && filter.show}">
      <span>{{ FILTER.type[filter.type]&&FILTER.type[filter.type].name || '类型' }}</span>
      <img :src="arrow" class="arrow" alt="大力盘">
      <ul v-show="index == 1 && filter.show">
        <li @click.stop="chooseFilterType(-1)">全部</li>
        <li v-for="item in filter_type_keys" :key="FILTER.type[item].name" @click.stop="chooseFilterType(item)">{{FILTER.type[item].name}}</li>
      </ul>
    </div>
    <div class="filter-item" @click.stop="chooseFilterItem(2)" :class="{'active': index == 2 && filter.show}">
      <span>{{ FILTER.size[filter.size]&&FILTER.size[filter.size].name || '大小' }}</span>
      <img :src="arrow" class="arrow" alt="大力盘">
      <ul v-show="index == 2 && filter.show">
        <li @click.stop="chooseFilterSize(-1)">全部</li>
        <li v-for="item in filter_size_keys" :key="FILTER.size[item].name" @click.stop="chooseFilterSize(item)">{{FILTER.size[item].name}}</li>
      </ul>
    </div>
    <div class="filter-item" @click.stop="chooseFilterItem(3)" :class="{'active': index == 3 && filter.show}">
      <span>{{ FILTER.time[filter.time]&&FILTER.time[filter.time].name || '时间' }}</span>
      <img :src="arrow" class="arrow" alt="大力盘">
      <ul v-show="index == 3 && filter.show">
        <li @click.stop="chooseFilterTime(-1)">全部</li>
        <li v-for="item in filter_time_keys" :key="FILTER.time[item].name" @click.stop="chooseFilterTime(item)">{{FILTER.time[item].name}}</li>
      </ul>
    </div>
    <div class="clear-button" @click="clearFilter">
      <span>清除</span>
      <img :src="clear" class="clear" alt="大力盘">
    </div>
  </div>
</template>
<script>
import { FILTER } from '@/constants/common'
const TIME_FILTER_KEYS = Object.keys(FILTER.time).sort()
const TYPE_FILTER_KEYS = Object.keys(FILTER.type).sort()
const SIZE_FILTER_KEYS = Object.keys(FILTER.size).sort()
const SEARCH_TYPE_FILTER_KEYS = Object.keys(FILTER.searchtype).sort()

export default {
  props: ['keyword', 'time', 'type', 'size','searchtype'],
  data () {
    return {
      arrow: require('@/assets/icons/arrow.png'),
      clear: require('@/assets/icons/clear.png'),
      index: 0,
      FILTER: FILTER,
      filter_time_keys: TIME_FILTER_KEYS,
      filter_type_keys: TYPE_FILTER_KEYS,
      filter_size_keys: SIZE_FILTER_KEYS,
      filter_search_type_keys: SEARCH_TYPE_FILTER_KEYS,
    }
  },
  mounted () {
    const data = {}
    if (this.time) data.time = this.time
    if (this.type) data.type = this.type
    if (this.size) data.size = this.size
    if (this.searchtype) data.searchtype = this.searchtype
    this.$store.commit('changeFilter', data)
  },
  methods: {
    chooseFilterItem (index) {
      this.$store.commit('changeFilter', { show: true })
      this.index = index
    },
    chooseFilterTime (index) {
      this.$store.commit('changeFilter', { time: index })
      this.updatePage()
    },
    chooseFilterSize (index) {
      this.$store.commit('changeFilter', { size: index })
      this.updatePage()
    },
    chooseFilterType (index) {
      this.$store.commit('changeFilter', { type: index })
      this.updatePage()
    },
    chooseFilterSearchType (index) {
      this.$store.commit('changeFilter', { searchtype: index })
      this.updatePage()
    },
    
    clearFilter () {
      this.$store.commit('changeFilter', {
        time: -1,
        type: -1,
        size: -1,
        searchtype: -1
      })
      this.updatePage()
    },
    updatePage () {
      const query = {
        keyword: this.keyword
      }
      if (this.filter.size !== -1) {
        query.size = this.filter.size
      }
      if (this.filter.time !== -1) {
        query.time = this.filter.time
      }
      if (this.filter.type !== -1) {
        query.type = this.filter.type
      }
      if (this.filter.searchtype !== -1) {
        query.searchtype = this.filter.searchtype
      }
      
      this.$router.replace({path: '/app/search', query})
      this.$store.commit('changeFilter', { show: false })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_var.scss';

.filter-wrap {
  display: flex;
  font-size: 12px;
  color: #666;
  align-items: center;
  margin-bottom: 10px;
  label {
    margin-right: 10px;
  }
  .filter-item {
    padding: 4px 15px;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.2s;
    .arrow {
      width: 11px;
      vertical-align: 1px;
      margin-left: 4px;
    }
    &:hover,&.active {
      background-color: #eee;
    }
    .new {
      color:$theme-color;
      font-weight: bold;
    }
    ul {
      top: 35px;
      left: 0;
      width: 110px;
      z-index: 10;
      position: absolute;
      list-style: none;
      border-radius: 4px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      li {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        transition: all ease 0.2s;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .clear-button {
    padding: 6px 15px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    background-color: $theme-color;
    transition: all ease 0.2s;
    &:hover {
      background-color: darken($theme-color, 10%);
    }
    &:active {
      background-color: darken($theme-color, 15%);
    }
    .clear {
      height: 12px;
      vertical-align: middle;
    }
  }
}
</style>

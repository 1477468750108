<template>
  <div class="search-page" v-loading="loading" ref="page">
    <div class="page-inner" v-if="!loading">
      <template v-if="keyword && results.length > 0">
        <filter-component :keyword="keyword" :size='size' :type="type" :time="time" :searchtype="searchtype" />
        <p class="tip"> 
          <span>共搜索出 <span class="em theme">{{ total }}</span> 条结果。</span> 
        </p>
        <template v-for="(item, index) in results">
          <resource-item 
            :data="item" 
            :keyword="keyword" 
            @add="addTask(item.res.id)"
            :key="`search-${item.res.filename}-${index}`" 
          />
        </template>
        <el-pagination 
          :current-page.sync="page"
          @current-change="pageChange"
          background
          layout="prev, pager, next" 
          :total="total > 3000 ? 3000 : total" :page-size="15"
          hide-on-single-page
        />
      </template>
      <div class="sensitive" v-if="!data">
        <img src="https://res.oodcd.cn/zhi/nothing.png" class="none-image" alt="没有搜到任何结果">
        <p class="none-tip" v-if="keyword">请求出错，请稍后重试。</p>
        <p class="none-tip" v-if="!keyword">关键词为空，请输入关键词进行搜索</p>
      </div>
      <div class="sensitive" v-if="results.length == 0 && data && data.resources === null">
        <img src="https://res.oodcd.cn/zhi/nothing.png" class="none-image" alt="没有搜到任何结果">
        <p class="none-tip">没有搜到 <span class="em">『{{keyword}}』</span> 的任何结果，可以切换『模糊搜索』搜一搜</p>
      </div>
      <div class="sensitive" v-if="data === 'blocked'">
        <img src="https://res.oodcd.cn/zhi/nothing.png" class="none-image" alt="当前IP禁止访问，如有疑问，请点击右下角留言反馈"> 
        <p class="none-tip">当前IP禁止访问，若需要解封，请点击右下角留言反馈。</p>
        <p class="none-tip">如你觉得判断有问题可以<a href="http://alibabachanpinjingliwenjuan.mikecrm.com/Mzb3Hpe" target="_blank">->点此在线反馈问题</a></p>
      </div>
      <div class="sensitive" v-if="data === 'toomuch' || data === 'limited'">
        <img src="https://res.oodcd.cn/zhi/nothing.png"  class="none-image" alt="您的搜索过于频繁，建议稍后尝试！">
        <p class="none-tip">您的搜索过于频繁，建议稍后尝试！</p>
      </div>
      <div class="sensitive" v-if="data==='politics'  || data==='porn'  || data==='contraband'  || data==='terrorism'  || data==='privacy'">
        <img src="https://res.oodcd.cn/zhi/nothing.png"  class="none-image" alt="您的搜索过于频繁，建议稍后尝试！">
        <p class="none-tip">你搜索的 <span class="em">『{{keyword}}』</span> 包含违禁词，请文明搜索！</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileType } from '@/util'
import { getSearchResult, getDetail } from '@/api/search'
import FilterComponent from '@/components/Filter.vue'
import ResourceItem from '@/components/ResourceItem.vue'

export default {
  data () {
    return {
      data: null,
      size: -1,
      type: -1,
      time: -1,
      searchtype: -1,
      page: 1,
      total: 0,
      results: [],
      keyword: '',
      loading: false,
    }
  },
  components: {
    ResourceItem,
    FilterComponent
  },
  watch: {
    '$route': function (to) {
      this.setRoute(to.query)
      this.refresh()
      if (this.$refs.page) {
        this.$refs.page.scrollTop = 0
      }
    }
  },
  async mounted () {
    this.setRoute(this.$route.query)
    await this.refresh()
  },
  methods: {
    setRoute (query) {
      const { keyword, page = 1, size, type, time, searchtype } = query
      this.size = size
      this.type = type
      this.time = time
      this.page = parseInt(page)
      this.keyword = keyword
      this.searchtype = searchtype
    },
    async refresh () {
      this.loading = true
      const res = await getSearchResult({ 
        page: this.page, 
        size: this.size, 
        type: this.type, 
        time: this.time, 
        keyword: this.keyword, 
        searchtype: this.searchtype,
      })
      this.loading = false

      if (res && res.resources) {
        this.results = res.resources.map((item) => {
          item.res.fileType = item.res.isdir !== 1 ? getFileType(`test.${item.res.ext}`) : 'filetype_folder'
          return item
        })
        this.total = res.total
      } else {
        this.results = []
        this.total = 0
      }
      this.data = res
    },
    async pageChange (page) {
      const temp = JSON.parse(JSON.stringify(this.$route.query))
      this.$router.push({ path: '/app/search', query: Object.assign(temp, { page, t: new Date().getTime() }) })
    },
    async addTask (id) {
      this.loading = true
      const res = await getDetail({ id })
      this.loading = false
      if (res.url) {
        global.linkInput = res.url
        global.linkPwd = res.pwd
        if (this.uploadDialog) {
          this.closeUploadDialog()
          this.$nextTick(() => {
            this.showUploadDialog()
          })
        } else {
          this.showUploadDialog()
        }
      } else {
        this.$message.error('操作过于频繁，请稍后重试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_var.scss";
.search-page {
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    width: 4px;
    border-radius: 4px;
    background-color: #f8f8f8;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 3px;
    background: #999;
  }

  .page-inner {
    margin: 10px 20px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fff;
    .tip {
      font-size: 12px;
      color: #666;
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }
  }

  .sensitive {
    text-align: center;
    padding: 40px;
    .none-image {
      width: 200px;
    }
    .none-tip {
      color: #666;
      margin-top: 20px;
      margin-bottom: 10px;
      .em {
        color: $theme-color;
      }
    }
  }
}
  
</style>
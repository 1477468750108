<template>
   <div class="resource-item-wrap">
    <div class="resource-item">
      <img :src="require(`@/assets/filetype/${data.res.fileType}.png`)" class="filetype" :alt="(data.highs && data.highs.filename && data.highs.filename[0]) || data.res.filename || 1" >
      <div class="resource-info">
        <h1 class="resource-title">
          <a 
          @click="addTask"
          href="javascript:void(0);"
          v-html="(data.highs && data.highs.filename && data.highs.filename[0]) || data.res.filename || 1"
          >
          </a>
        </h1>
        <div class="detail-wrap" v-if="data.res.filelist && data.res.filelist.length > 0">
          <template v-for="(detailItem, detailIndex) in data.res.filelist" >
            <p class="detail-item-wrap" :key="`detail${detailIndex}`">
              <span class="detail-item-title" v-html="detailItem.filename"></span>
              <span v-if="detailItem.isdir !== 1"> - {{detailItem.size | bytesToSize}}</span>
            </p>
          </template>
          <p class="detail-item-wrap"  v-if="data.res.filelist.length > 9">
            <span class="">......</span>
          </p>
        </div>
        <div class="resource-meta">
          <span class="meta-item"><span class="label">文件大小</span>  <span class="em">{{ data.res.size | bytesToSize }}</span> </span>
          <el-button 
            type="primary" 
            size="mini"
            icon="el-icon-plus"
            @click="addTask"
          >添加任务
          </el-button>
        </div>
      </div>
      <div class="other-info">
        <p class="time">{{data.res.utime.substr(0, 10)}}</p>
      </div>        
    </div>
  </div>
</template>

<script>

export default {
  props: ["data", 'valid'],
  data () {
    return {}
  },
  methods: {
    addTask () {
      this.$emit('add')
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/_var.scss";
  .resource-item-wrap {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  .resource-item {
    padding: 15px 0;
    display: flex;
    border-bottom: 1px dashed #eee;
    .filetype {
      height: 24px;
      margin-right: 10px;
      flex: none;
    }
    .resource-info {
      flex: auto;
      h1 {
        word-break: break-all;
        font-size: 14px;
        color: #333;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        a {
          color: inherit;
          cursor: pointer;
          &:hover {
            color: $theme-color;
          }
        }
      }
      mark {
        color: lighten($theme-color, 5%);
        background-color: transparent;
      }
      .detail-wrap {
        margin-top: 15px;
        padding: 10px;
        border-radius: 2px;
        background-color: #f8f8f8;
        width: 100%;
        .detail-item-wrap {
          span {
            font-size: 12px;
            display: inline;
            line-height: 1.8;
            color: #666;
          }
          .detail-item-title {
            font-weight: normal;
            position: relative;
            padding-left: 15px;
            display: inline;
            word-break: break-all;
            &:before {
              content: "|-";
              position: absolute;
              top: -6px;
              left: 0;
            }
          }
        }
      }
      .resource-meta {
        margin-top: 10px;
        font-size: 12px;
        color: #666;
        .meta-item {
          margin-right: 6px;
          display: inline-block;
          border-radius: 2px;
          padding-right: 4px;
          border: 1px solid #ddd;
          background-color: #f8f8f8;
          .label {
            display: inline-block;
            padding: 2px 4px;
            margin-right: 4px;
            background: #f5f5f5;
            border-right: 1px solid #ddd;
          }
          .em {
            font-weight: bold;
          }
        }
        .el-button {
          margin-left: 10px;
        }
      }
    }
    .other-info {
      min-width: 120px;
      .time {
        text-align: right;
        color: #999;
        font-size: 12px;
        a {
          color: inherit;
          font-weight: normal;
        }
      }
    }
    .button-wrap {
      display: inline;
      text-align: center;
      .button-inner {
        margin-right:5px;
        margin-left:5px;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
        &.baidu-button-inner {
          .button {
            background-color: #666;
            &:hover {
              background-color: darken(#666, 10%);
            }
          }
        }
      }
      .button {
        display: inline-block;
        color: #fff;
        height: 23px;
        line-height: 23px;
        width: 100px;
        border-radius: 2px;
        background-color: $theme-color;
        transition: background-color ease 0.3s;
        text-decoration: none;
        &:hover {
          background-color: darken($theme-color, 10%);
        }
        &.invalid {
          color: #ddd;
          text-decoration: line-through;
        }
      }
    }
  }
</style>
